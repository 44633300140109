'use strict'

const React = require('react')
const Form = require('../forms/generic')
const SearchInput = require('../inputs/search')
const types = require('../../types')
const styles = require('./header.css')
const connect = require('../connect')
const searchSuggest = require('../../../client/actions/search-suggest')
const Typeahead = require('../typeahead/typeahead')
const go = require('../../../client/actions/go')
const ButtonSubmit = require('../forms/submit-button')

class SearchBar extends React.PureComponent {
  search(name) {
    this.props.dispatch(go(`/package/${name}`))
  }

  getSuggestions() {
    return this.props.dispatch(searchSuggest())
  }

  renderSuggestion(object, index) {
    const {name, description, version} = object
    return index >= 5 ? (
      name
    ) : (
      <div className={styles.searchTypeahead}>
        <div>
          <strong>{name}</strong>
          <p>{description}</p>
        </div>
        <span className="fr db">{version}</span>
      </div>
    )
  }

  render() {
    const formId = 'search'
    const {formData = {[formId]: {}}} = this.props

    return (
      <div id="search" className={styles.search}>
        <Form
          className={styles.searchForm}
          action="/search"
          method="GET"
          formId={formId}
          formData={formData}
          showButton={false}
        >
          <Typeahead
            inputElem={
              <SearchInput name="q" hotkeys={{focus: '/'}} formData={formData} placeholder="Search packages" />
            }
            getSuggestions={() => this.getSuggestions()}
            renderRow={this.renderSuggestion}
            name="q"
            formData={formData}
            onSelect={name => this.search(name)}
            aria-label="Search packages"
            title="Search results"
            className={styles.typeahead}
          />
          <ButtonSubmit className={styles.searchButton} buttonAriaLabel="Search">
            Search
          </ButtonSubmit>
        </Form>
      </div>
    )
  }
}

SearchBar.propTypes = {
  formData: types.formData,
}

module.exports = connect()(SearchBar)
