'use strict'
const React = require('react')
const StaticComponent = require('../static')

class TwitterColoredIcon extends StaticComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none" aria-hidden="true">
        <path
          d="M10.7665 2.55588C10.7741 2.66247 10.7741 2.76909 10.7741 2.87568C10.7741 6.12693 8.2995 9.87313 3.77665 9.87313C2.38324 9.87313 1.08884 9.46956 0 8.76909C0.197977 8.79191 0.388312 8.79953 0.593906 8.79953C1.74363 8.79953 2.80202 8.41122 3.6472 7.74878C2.56598 7.72593 1.65989 7.01781 1.3477 6.04319C1.5 6.06602 1.65227 6.08125 1.81219 6.08125C2.03299 6.08125 2.25382 6.05078 2.45939 5.99751C1.33249 5.76906 0.487289 4.77923 0.487289 3.5838V3.55335C0.814687 3.73609 1.19543 3.8503 1.59895 3.86552C0.936516 3.42388 0.502523 2.67009 0.502523 1.81729C0.502523 1.36045 0.624328 0.941664 0.837539 0.57618C2.0482 2.06856 3.86801 3.04316 5.90859 3.14978C5.87053 2.96704 5.84768 2.7767 5.84768 2.58634C5.84768 1.231 6.94413 0.126953 8.30707 0.126953C9.01519 0.126953 9.65477 0.423906 10.104 0.903602C10.6598 0.797008 11.1928 0.591414 11.6649 0.309695C11.4822 0.880773 11.0939 1.36047 10.5837 1.66502C11.0786 1.61174 11.5583 1.47466 12 1.28432C11.665 1.77161 11.2462 2.2056 10.7665 2.55588Z"
          fill="#2698D9"
        />
      </svg>
    )
  }
}

module.exports = TwitterColoredIcon
